import styled from 'styled-components';

import { container } from 'theme/sizes';
import { toRem } from 'utils/mixins';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${container.xsmall};
  width: calc(100% - 2.5rem);
  margin: ${toRem(40)} auto;
  text-align: center;

  h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }

  @media (min-width: 768px) {
    margin: ${toRem(80)} auto;
  }
`;
