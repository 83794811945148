import React from 'react';

import HTMLReactParser from 'html-react-parser';

import { Wrapper } from './TextBlock.styles';

const TextBlock = (props) => {
  //PROPS
  const { data } = props;
  //PROPS

  return <Wrapper>{!!data && HTMLReactParser(data)}</Wrapper>;
};

export default TextBlock;
